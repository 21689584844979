"use client";

import { getImageProps } from "next/image";
import { stringifySrcset } from "srcset";
import getMediaUrl from "@/utils/getMediaUrl";
import { useEffect, useState } from "react";
import cn from "@/utils/cn";

const tmp_src =
  "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8/wcAAgAB/UsVSIAAAAASUVORK5CYII";

const entries = [
  ["thumbnail", 245],
  ["small", 500],
  ["medium", 750],
  ["large", 1000],
  ["xlarge", 1400],
  ["xxlarge", 1920],
];

const getEntriesBasedOnMaximumSize = (file, maximumSize) => {
  if (!maximumSize) return [];

  if (typeof file === "string") return [];

  const _entries = entries.filter(([format]) => !!file?.formats?.[format]);
  if (!maximumSize) return _entries;

  const index = _entries.findIndex(([format]) => format === maximumSize);
  if (index === -1) return _entries;

  return _entries.slice(0, index + 1);
};

export default function ImageWrapper({
  alt = "",
  maximumSize,
  file,
  src,
  blurDataUrl,
  hasPlaceholder,
  ...props
}) {
  const [extraProps, setExtraProps] = useState({
    className: props.className,
    width: props.width,
    height: props.height,
    style: props.style,
    src: blurDataUrl ?? tmp_src,
    srcSet: "",
  });
  const _entries = getEntriesBasedOnMaximumSize(file, maximumSize);

  const imgProps = getImageProps({
    ...props,
    src,
    overrideSrc: src,
  });

  const withWidth = _entries.map(([format, width]) => ({
    url: getMediaUrl(file, format),
    width,
  }));

  const srcSet = stringifySrcset(typeof file !== "string" ? withWidth : []);

  useEffect(() => {
    setExtraProps({ ...imgProps.props, srcSet });
  }, []);

  // console.log(src, extraProps);

  if (!extraProps.src) return <img alt={alt} {...extraProps} src={tmp_src} />;

  // eslint-disable-next-line @next/next/no-img-element
  return <img alt={alt} srcSet={srcSet} {...extraProps} />;
}
